import React from "react";
import neewee from "../../images/about/newee.webp";

let Neewee = () => {
  return (
    <>
      <div className="container my-5">
        <div className="roundBorder">
          <div className="row">
            <div className="col-12 mb-5">
              <h2 className="titleServ">
                Neewee – A Global Integrated Industrial Analytics Company
              </h2>
              <br />
              <div className="lineServ"></div>
            </div>
            <div className="col-lg-6 mt-20">
              <div className="caseImg">
                <img src={neewee} alt="" />
              </div>
            </div>
            <div className="col-lg-6 mt-20">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <span>OUR THESIS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>
                          <b>Founding Team</b> – Average relevant experience of
                          15+ years with global MNCs in senior leadership levels
                        </p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">
                          <b>Landscape</b> – 90% of companies were Point
                          solutions providing siloed analytics as compared to
                          Neewee’s integrated global product
                        </p>
                      </div>
                      <div className="oneee">
                        <p>
                          <b>Proof of Concept</b> – Multiple Repeat Sales with
                          one of the Largest Aviation OEMs
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span>INVESTORS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>IIFL Asset Management</p>
                        <p>Siana Capital</p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">Canada Based Family Office</p>
                      </div>
                      <div className="oneee">
                        <p>Radix Capital Advisors</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span>CURRENT STATUS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>
                          <b>Leaping ahead through Innovation</b> – Developed 6
                          APPs, delivering quantifiable ROIs through Outcome
                          focused, Simple to configure Use-cases
                        </p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">
                          <b>Gaining Traction</b> – From One large Client to 8
                          Large clients, Neewee’s innovations are driving rapid
                          adoptions
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center my-5">
          <a href="/Sunsure">
            <button className="btnArrow">
              <i className="fa-solid fa-arrow-left"></i> Sunsure
            </button>
          </a>
          &nbsp;&nbsp;
          <a href="/snapmint">
            <button className="btnArrow">
              Snapmint <i className="fa-solid fa-arrow-right"></i>
            </button>
          </a>
        </div>
      </div>
    </>
  );
};
export default Neewee;
