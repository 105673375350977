import React from "react";

import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";
import Serv from "./Serv";

let Services = () => {
  return (
    <>
      <section className="about-area pt-50 pb-100">
        <div className="container">
          <div className="section-header mb-40">
            <div className="section-header text-center mb-50">
              <h4 className="section-title mb-25">
                <span>
                  <img src={shape1} className="mr-10" alt="" />
                </span>
                Services
                <span>
                  <img src={shape2} className="ml-10" alt="" />
                </span>
              </h4>
            </div>
          </div>
          <Serv />
        </div>
      </section>
    </>
  );
};
export default Services;
