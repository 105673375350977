import React from "react";
import "./accordian.css";
import freshee from "../../images/about/freshee.webp";

let Freshee = () => {
  return (
    <>
      <div className="container my-5">
        <div className="roundBorder">
          <div className="row">
            <div className="col-12 mb-5">
              <h2 className="titleServ">
                Freshee - One of the Fastest Growing Consumer Brand in Freshness
                & Hygiene
              </h2>
              <br />
              <div className="lineServ"></div>
            </div>
            <div className="col-lg-6 mt-20">
              <div className="caseImg">
                <img src={freshee} alt="" />
              </div>
            </div>
            <div className="col-lg-6 mt-20">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <span>OUR THESIS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>
                          <b>Founding Team</b> – Industry Veteran, Proven
                          capabilities, Passion and clear vision
                        </p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">
                          <b>Landscape</b> – Very Large but Fragmented &
                          Unorganized Industry
                        </p>
                      </div>
                      <div className="oneee">
                        <p>
                          <b>Proof of Concept</b> – Differentiated Product,
                          Distribution First Approach, Repeat Sales in Cash and
                          Carry Model
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span>INVESTORS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>Edelweiss PE & VC</p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">Intellegrow</p>
                      </div>
                      <div className="oneee">
                        <p>Kan Lakhani Family Office</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span>CURRENT STATUS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>
                          <b>Scale </b> – 4x Revenue growth within three years
                        </p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">
                          <b>Distribution</b> – Pan India presence in General as
                          well as Modern Trade. Globally, it exports to 18
                          countries
                        </p>
                      </div>
                      <div className="oneee">
                        <p>
                          <b>Platform for Market Dominance</b> – All groundwork
                          done for next phase of growth to become a global brand
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center my-5">
          <a href="/sukhhi">
            <button className="btnArrow">
              <i className="fa-solid fa-arrow-left"></i> Sukhhi
            </button>
          </a>
          &nbsp;&nbsp;
          <a href="/HR-one">
            <button className="btnArrow">
              HROne <i className="fa-solid fa-arrow-right"></i>
            </button>
          </a>
        </div>
      </div>
    </>
  );
};

export default Freshee;
