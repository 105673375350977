import React from "react";
import {Helmet} from "react-helmet";
import About from "../Home/About";
import Approach from "./Approach";
import Principle from "./Principle";
import Whoweare from "./Whoweare";

let AboutUs = () => {
  return (
    <>

      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - Radixcap | Augmenting Value</title>
        <meta name="format-detection" content="telephone=no" />
        <link rel="canonical" href="http://radixcap.in/" />
      </Helmet>


      <Whoweare />
      <About />
      <Principle />
      <Approach />
    </>
  );
};

export default AboutUs;
