import React from "react";
import logo from "../images/logo/logo.png";

let Footer = () => {
  return (
    <>
      <footer className="footer pt-60">
        <div className="container">
          <div className="row mt-none-50 justify-content-center">
            <div className="col-xl-4 col-lg-4 mt-50">
              <a href="/" className="footer__logo">
                <img src={logo} className="logoImg" alt="" />
              </a>
            </div>
            <div className="col-xl-4 col-lg-4 mt-50 pl-45 pr-0">
              <div className="footer-widget">
                <h4 className="widget-title">Our Links</h4>
                <ul>
                  <li>
                    <a href="/about_us">
                      <i className="fa fa-angle-right"></i> About Us
                    </a>
                  </li>
                  <li>
                    <a href="/team">
                      <i className="fa fa-angle-right"></i> Team
                    </a>
                  </li>
                  <li>
                    <a href="/service">
                      <i className="fa fa-angle-right"></i> Services
                    </a>
                  </li>
                  <li>
                    <a href="/credential">
                      <i className="fa fa-angle-right"></i> Credential
                    </a>
                  </li>
                  <li>
                    <a href="/case_study">
                      <i className="fa fa-angle-right"></i> Case Study
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 mt-50">
              <div className="footer-widget">
                <h4 className="widget-title">Reach Radixcap</h4>
                <div className="recent-news mt-none-20">
                  <div className="recent-news__content mt-20">
                    <a href="/" className="recent-news__title">
                    <b>Mumbai</b>
                    </a>

                    <a href="/" className="recent-news__date">
                    <i class="fa-solid fa-location-dot"></i> &nbsp; 91 Springboard, Lotus, <br />
                    6th
                      Floor,  Plot No. D-5 Road No. <br /> 20, Marol MIDC,
                      Andheri East, <br />
                      Mumbai, Maharashtra 400093
                    </a>
                    {/* <br />
                    <a href="mailto:info@radixcap.in" className="recent-news__date">
                      {" "}
                      <i className="fa fa-envelope"></i> info@radixcap.in
                    </a> */}
                  </div>
                  <div className="recent-news__content mt-20">
                    <a href="/" className="recent-news__title">
                    <b>Bangalore</b>
                    </a>

                    <a href="/" className="recent-news__date">
                    <i class="fa-solid fa-location-dot"></i> &nbsp;BHIVE Workspace, No.467/468, <br />
                    Shri Krishna Temple Rd, <br />  Indira Nagar 1st Stage,<br />
                    Karnataka,Bengaluru 560038
                    </a>
                    <br />
                    <a href="mailto:info@radixcap.in" className="recent-news__date">
                      {" "}
                      <i className="fa fa-envelope"></i> info@radixcap.in
                    </a>
                  </div><br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 my-auto">
                <div className="copyright-text">
                  <p>
                    Copyright &copy; <a href="/">Radixcap.</a> All Rights
                    Reserved | Made with <i class="fa-solid fa-heart"></i> by <a href="https://webxinfinity.com/" target="_blank" rel="noreferrer">Webxinfinity IT Solution</a>
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="social__links">
                  <a
                    href="https://www.linkedin.com/company/radix-capital-advisors-pvt-ltd-/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
