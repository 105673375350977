import React from "react";
import neewee from "../../images/about/su.png";
import a from "../../images/about/SSunsure.png";
import b from "../../images/about/tata.png";
import c from "../../images/about/it.png";

let Sunsure = () => {
  return (
    <>
      <div className="container my-5">
        <div className="roundBorder">
          <div className="row">
            <div className="col-12 mb-5">
              <h2 className="titleServ">
                Sunsure – Leading the nation's sustainable energy revolution as
                a premier renewable energy company
              </h2>
              <br />
              <div className="lineServ"></div>
            </div>
            <div className="col-lg-6 mt-20">
              <div className="caseImg">
                <img src={neewee} alt="" />
              </div>
            </div>
            <div className="col-lg-6 mt-20">
              <div class="accordion" id="accordionExample">
                {/* <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <span>OUR THESIS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>
                          <b>Founding Team</b> – Average relevant experience of
                          15+ years with global MNCs in senior leadership levels
                        </p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">
                          <b>Landscape</b> – 90% of companies were Point
                          solutions providing siloed analytics as compared to
                          Sunsure’s integrated global product
                        </p>
                      </div>
                      <div className="oneee">
                        <p>
                          <b>Proof of Concept</b> – Multiple Repeat Sales with
                          one of the Largest Aviation OEMs
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <span>INVESTORS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <a href="https://www.tatacapital.com/">
                          <img src={b} alt="" width="250px" height="150px" />
                        </a>
                        <a href="https://www.ltfs.com/">
                          <img src={c} alt="" width="250px" height="150px" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span>CURRENT STATUS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>
                          <b>Leaping ahead through Innovation</b> – Developed 6
                          APPs, delivering quantifiable ROIs through Outcome
                          focused, Simple to configure Use-cases
                        </p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">
                          <b>Gaining Traction</b> – From One large Client to 8
                          Large clients, Sunsure’s innovations are driving rapid
                          adoptions
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <span>Story after association with Radix</span>
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>Acquired by Partners Group in 2022 for $400M</p>
                        <p>Institutional Funding for platform play</p>
                      </div>
                      {/* <div className="twooo">
                        <p className="pp1">
                          <b>Landscape</b> – 90% of companies were Point
                          solutions providing siloed analytics as compared to
                          Neewee’s integrated global product
                        </p>
                      </div>
                      <div className="oneee">
                        <p>
                          <b>Proof of Concept</b> – Multiple Repeat Sales with
                          one of the Largest Aviation OEMs
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-80">
              <h2 className="titleServ">
                Low differentiation yet high conviction!
              </h2>
              <br />
              <div className="lineServ"></div>
            </div>
            <div class="flip-box-container">
              <div class="flip-box">
                <div class="flip-box-inner">
                  <div class="flip-box-front">
                    <h3>2018 – What market believed</h3>
                    {/* <p>Front content goes here</p> */}
                  </div>
                  <div class="flip-box-back">
                    <ol>
                      <li>
                        Solar EPC is <b>commoditized market</b>, no equity
                        before certain scale.
                      </li>
                      <li>
                        People <b>did not have positive outlook</b> on EPC
                        companies for residential purpose.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>

              <div class="flip-box">
                <div class="flip-box-inner">
                  <div class="flip-box-front">
                    <h3>Radix thesis</h3>
                    {/* <p>Front content goes here</p> */}
                  </div>
                  <div class="flip-box-back">
                    <ol>
                      <li>
                        India’s solar rooftop market will be dominated by{" "}
                        <b>off-grid commercials & industrial users</b> for
                        cheaper energy supply.
                      </li>
                      <li>
                        The <b>financial impact from EPC </b>doing well{" "}
                        <b>will be massive</b> for the investors
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-80 mb-30">
            <h2 className="titleServ">
              Sunsure – Emulating Renew Power’s Institutional Funding
            </h2>
            <br />
            <div className="lineServ"></div>
          </div>

          <div className="content">
            <div className="row">
              <div className="col-md-6">
                <img src={a} alt="Snapmint Image" className="img-fluid" />
              </div>
              <div className="col-md-6">
                <div className="additional-info1">
                  <h1 class="abc">
                    <strong>Sunsure Today</strong>
                    <div className="lineServ"></div>
                  </h1>
                  <br />
                  <br />
                  {/* <br /> */}
                  <br />
                  <h2>
                    <strong>
                      Acquired by Partners <br />
                      Group for $400M <br />
                      In 2022
                    </strong>
                  </h2>
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center my-5">
          <a href="/Medkart">
            <button className="btnArrow">
              <i className="fa-solid fa-arrow-left"></i> Medkart
            </button>
          </a>
          &nbsp;&nbsp;
          <a href="/neewee">
            <button className="btnArrow">
              Neewee <i className="fa-solid fa-arrow-right"></i>
            </button>
          </a>
        </div>
      </div>
    </>
  );
};
export default Sunsure;
