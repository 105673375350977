import React from "react";
import { Helmet } from "react-helmet";
import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

import a from "../../images/team/a.webp";
import b from "../../images/team/b.webp";
import c from "../../images/team/c.webp";
import d from "../../images/team/d.webp";
import e from "../../images/team/e.webp";
import f from "../../images/team/f.webp";

let Team = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Team - Radixcap | Augmenting Value</title>
        <meta name="format-detection" content="telephone=no" />
        <link rel="canonical" href="http://radixcap.in/" />
      </Helmet>

      <section className="about-area pt-50 pb-50 animate__animated animate__fadeInUp animate__delay-1s animate__slow	2s">
        <div className="container">
          <div className="section-header pl-80">
            <div className="section-header text-center mb-50">
              <h4 className="section-title mb-25">
                <span>
                  <img src={shape1} className="mr-10" alt="" />
                </span>
                Our Team
                <span>
                  <img src={shape2} className="ml-10" alt="" />
                </span>
              </h4>
            </div>
          </div>

          <div className="row mb-40" id="cardrrrr">
            <div className="col-lg-4 mb-10" id="carrrd">
              <div className="lineV"></div>
              <div className="cardTee">
                <li className="nav-item">
                  <a href="/abhishek">
                    <img src={b} alt=" profile " className="img-responsive" />
                    <h6 className="namePosition">
                      Abhishek Taparia <br />
                      <span>
                        <small>(Co-Founder & CEO)</small>
                      </span>
                    </h6>
                  </a>
                </li>
              </div>
            </div>

            <div className="col-lg-4 mb-10" id="carrrd">
              <div className="lineV"></div>
              <div className="cardTee">
                <li className="nav-item">
                  <a href="/maya">
                    <img src={c} alt=" profile " className="img-responsive" />
                    <h6 className="namePosition">
                      Maya Taparia <br />
                      <span>
                        <small>(Co-Founder & COO)</small>
                      </span>
                    </h6>
                  </a>
                </li>
              </div>
            </div>

            <div className="col-lg-4 mb-10" id="carrrd">
              <div className="lineV"></div>
              <div className="cardTee">
                <li className="nav-item">
                  <a href="/mahesh">
                    <img src={a} className="img-responsive" alt="profile" />
                    <h6 className="namePosition">
                      Mahesh T.D. <br />
                      <span>
                        <small>(VP)</small>
                      </span>
                    </h6>
                  </a>
                </li>
              </div>
            </div>

            <div className="col-lg-4 mb-10" id="carrrd">
              <div className="lineV"></div>
              <div className="cardTee">
                <li className="nav-item">
                  <a href="/ashish">
                    <img src={f} alt=" profile " className="img-responsive" />
                    <h6 className="namePosition">
                      Ashish Chacko
                      <br />{" "}
                      <span>
                        <small>(Sr. Analyst)</small>
                      </span>
                    </h6>
                  </a>
                </li>
              </div>
            </div>

            <div className="col-lg-4 mb-10" id="carrrd">
              <div className="lineV"></div>
              <div className="cardTee">
                <li className="nav-item">
                  <a href="/akash">
                    <img src={e} alt=" profile " className="img-responsive" />
                    <h6 className="namePosition">
                      Akash Bagul <br />{" "}
                      <span>
                        <small>(Analyst)</small>
                      </span>
                    </h6>
                  </a>
                </li>
              </div>
            </div>

            <div className="col-lg-4 mb-10" id="carrrd">
              <div className="lineV"></div>
              <div className="cardTee">
                <li className="nav-item">
                  <a href="/vignesh">
                    <img src={d} alt=" profile " className="img-responsive" />
                    <h6 className="namePosition">
                      Vignesh Ramanan <br />{" "}
                      <span>
                        <small>(Analyst)</small>
                      </span>
                    </h6>
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
