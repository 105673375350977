import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useState } from "react";




  

let Contact = () => {


  const [formData, setFormData] = useState({});
  const form = useRef();

  function sendEmail(e){
    e.preventDefault();

    if(form.current.reportValidity()){
      emailjs.send('service_btc80hu', 'template_09857ew', formData, 'user_x3IQfffqEgzUjf7uOjR9Z')
      .then((result) => {
          console.log(result.text);
          window.location.href= window.location.origin +"/thankyou"
      }, (error) => {
          console.log(error.text);
      });
    }
   
  };

  return (
    <>
      <section className="gta-area pt-125 pb-50 animate__animated animate__fadeInUp animate__delay-10s animate__slow	2s">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 pr-50">
              <div id="contact-map">
                <img src="https://images.unsplash.com/photo-1545424920-b978eefcde48?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2025&q=80" alt="imgg" />
              </div>
            </div>
            <div className="col-xl-6 pl-50">
              <div className="section-header mb-10">
                <h4 className="sub-heading mb-10">Send Message</h4>
                <h2 className="section-title">Get in Touch</h2>
              </div>
              <div className="contact-form">
                <form ref={form} id="contact-form" name="contact-form">
                  <div className="form-group mt-35">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Your Name"
                      required
                      onChange={(event) => {
                        setFormData({ ...formData, name: event.target.value });
                      }}
                    />
                  </div>
                  <div className="form-group mt-35">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      required
                      onChange={(event) => {
                        setFormData({ ...formData, email: event.target.value });
                      }}
                    />
                  </div>
                  <div className="form-group mt-35">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Your Message"
                      required
                      onChange={(event) => {
                        setFormData({ ...formData, message: event.target.value });
                      }}
                    ></textarea>
                  </div>
                  <button onClick={(event) => sendEmail(event)} type="submit" name="submit" className="site-btn site-btn__s2 mt-15">
                    <span className="icon icon__black">
                      <i className="fa fa-arrow-right"></i>
                    </span>
                    Contact us
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
