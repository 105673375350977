import React from "react";

import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

import a1 from "../../images/about/a1.webp";
import a2 from "../../images/about/a2.webp";
import a3 from "../../images/about/a3.webp";

let Approach = () => {
  return (
    <>
      <section className="about-area pt-50 pb-50 animate__animated animate__fadeInUp animate__delay-7s animate__slow	2s">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-12 pl-80">
              <div className="section-header mb-40">
                <div className="section-header text-center mb-50">
                  <h4 className="section-title mb-25">
                    <span>
                      <img src={shape1} className="mr-10" alt="" />
                    </span>
                    Our Approach
                    <span>
                      <img src={shape2} className="ml-10" alt="" />
                    </span>
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-lg-4" id="width33">
              <img src={a1} id="onHoverTop" alt="" />
            </div>
            <div className="col-lg-4" id="width33">
              <img src={a2} id="onHoverTop" alt="" />
            </div>
            <div className="col-lg-4" id="width33">
              <img src={a3} id="onHoverTop" alt="" />
            </div>
          </div>

          <center>
            <p className="textAPP">
              “With a vision to build a Long Term Relationship, we invest in the
              present for a better tomorrow”
            </p>
          </center>
        </div>
      </section>
    </>
  );
};

export default Approach;
