import React from "react";

import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";
import b from "../../images/team/b.webp";
import c from "../../images/team/c.webp";
import d from "../../images/team/d.webp";
import e from "../../images/team/e.webp";
import f from "../../images/team/f.webp";

import ad from "../../images/team/mahesh.jpg";

let Mahesh = () => {
  return (
    <>
      <section className="about-area pb-50">
        <div className="section-header">
          <div className="section-header text-center">
            <img src={ad} className="w100" alt="" />
          </div>
        </div>
        <div className="container">
          <div className="tab-pane mt-50" id="maya">
            <div className="row">
              <div className="col-md-12">
                <div className="profile-details">
                  <h3>The Thinker</h3>
                  <hr />
                  <p>
                    “There is no happiness for him who does not travel, Rohita!{" "}
                    <br />
                    Thus we have heard. Living in the society of men, the best
                    man becomes a sinner… therefore, wander!… The fortune of him
                    who is sitting, sits; it rises when he rises; it sleeps when
                    he sleeps; it moves when he moves. Therefore, wander!”{" "}
                    <small>
                      <b>-Rigveda</b>
                    </small>
                  </p>
                  <br />
                  <p>
                    Mahesh lives by this verse from Rigveda. In his 12 years of
                    working, he has lived in 5 countries across 3 continents and
                    travelled to 18 more. Mahesh brings in an acute
                    understanding of different cultures and the way it interacts
                    with consumption and investment behaviours.
                  </p>
                  <br />
                  <p>
                    Prior to Radix, he worked with Air Liquide across India,
                    China, Germany and Australia. He has more than 12 years of
                    experience in new market entry, business strategy of a small
                    SBU in a large ecosystem, project management, engineering,
                    operations, procurement & finance. Having handled multiple
                    cross-functional and cross-cultural teams, he brings in the
                    ability to engage with stakeholders across multiple sectors.
                  </p>
                  <br />
                  <p>
                    Mahesh is torn between his love for sports and food. He
                    captained his university cricket team to a Gold medal in
                    Barcelona but is better known for feeding excellent tapas to
                    his teammates. He is also an avid quizzer and is the primary
                    source of trivia, within the team.
                  </p>
                  <br />
                  <p>
                    Mahesh has joined Radix, with a focus on developing
                    international relations, M&A advisory and helping the
                    start-ups with growth strategies.
                  </p>
                  <br />
                  <p>
                    He holds an MBA(Finance) from HEC Paris and B.Tech in
                    Manufacturing Technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area pt-40 pb-50 animate__animated animate__fadeInUp animate__delay-3s animate__slow	2s">
        <div className="container">
          <div className="section-header mb-100">
            <div className="section-header text-center mb-50">
              <h4 className="section-title mb-25">
                <span>
                  <img src={shape1} className="mr-10" alt="" />
                </span>
                Other Members
                <span>
                  <img src={shape2} className="ml-10" alt="" />
                </span>
              </h4>
            </div>
          </div>

          <div className="d-flex">
            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/abhishek">
                  <img src={b} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Abhishek Taparia <br />
                    <span>
                      <small>(Co-Founder & CEO)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/maya">
                  <img src={c} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Maya Taparia <br />
                    <span>
                      <small>(Co-Founder & COO)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/akash">
                  <img src={e} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                  Akash Bagul <br />{" "}
                    <span>
                      <small>(Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/vignesh">
                  <img src={d} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                  Vignesh Ramanan <br />{" "}
                    <span>
                      <small>(Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/ashish">
                  <img src={f} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Ashish Chacko <br />{" "}
                    <span>
                      <small>(Senior Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Mahesh;
