import React from "react";
import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

import a from "../../images/project/neewee.webp";
import b from "../../images/project/freshee.webp";
import e from "../../images/project/Hrone.png";
import c from "../../images/project/sukhhi.webp";
import d from "../../images/project/snapmint.webp";
import f from "../../images/project/Medikart1.png";
import g from "../../images/project/Sunsure1.png";

let Case = () => {
  return (
    <>
      <div className="container pt-100 animate__animated animate__fadeInUp animate__delay-6s animate__slow	2s">
        <div className="row justify-content-center">
          <div className="col-xl-8 text-center">
            <div className="section-header mb-50">
              <h4 className="section-title mb-25">
                <span>
                  <img src={shape1} className="mr-10" alt="" />
                </span>
                Case Studies
                <span>
                  <img src={shape2} className="ml-10" alt="" />
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row" id="cardrrrr">
          <div className="col-lg-3 mt-20" id="carrrd">
            <div className="cardCase">
              <img src={a} alt="Avatar" className="image" />
              <div className="overlay">
                <div>
                  <a href="/neewee" className="text">
                  Industrial Analytics company delivering Industry 4.0 benefits to clients across Manufacturing Industry      </a>{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mt-20" id="carrrd">
            <div className="cardCase">
              <img src={d} alt="Avatar" className="image" />

              <div className="overlay">
                <div>
                  <a href="/snapmint" className="text">
                  One of India's fastest growing Consumer Purchase Fintechs   </a>{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mt-20" id="carrrd">
            <div className="cardCase">
              <img src={c} alt="Avatar" className="image" />

              <div className="overlay">
                <div>
                  <a href="/sukhhi" className="text">
                  Internet First Fashion Jewellery Brand     </a>{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mt-20" id="carrrd">
            <div className="cardCase">
              <img src={b} alt="Avatar" className="image" />
              <div className="overlay">
                <div>
                  <a href="/freshee" className="text">
                  One of India's Largest Freshness & Hygiene Brands  </a>{" "}
                </div>
              </div>
            </div>
          </div>


          <div className="col-lg-3 mt-20" id="carrrd">
            <div className="cardCase">
              <img src={e} alt="Avatar" className="image" />
              <div className="overlay">
                <div>
                  <a href="/HR-one" className="text">
                  Mobile-first HRMS software with 127+ processes to simplify HR workday  </a>{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mt-20" id="carrrd">
            <div className="cardCase">
              <img src={f} alt="Avatar" className="image" />
              <div className="overlay">
                <div>
                  <a href="/Medkart" className="text">
                  India's most trusted offline retail generic pharmacy chain with 7lac+ customers  </a>{" "}
                </div>
              </div>
            </div>
          </div>


          <div className="col-lg-3 mt-20" id="carrrd">
            <div className="cardCase">
              <img src={g} alt="Avatar" className="image" />
              <div className="overlay">
                <div>
                  <a href="/Sunsure" className="text">
                  India's leading renewable EPC company powering India's growth  </a>{" "}
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  );
};
export default Case;
