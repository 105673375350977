import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

import a from "../../images/project/1.webp";
import b from "../../images/project/2.webp";
import c from "../../images/project/3.webp";
import d from "../../images/project/4.webp";
import e from "../../images/project/5.webp";
import f from "../../images/project/6.webp";
import g from "../../images/project/7.webp";
import h from "../../images/project/8.webp";
import i from "../../images/project/9.webp";
import j from "../../images/project/10.webp";
import k from "../../images/project/11.webp";
import l from "../../images/project/12.webp";
import m from "../../images/project/13.webp";
import n from "../../images/project/14.webp";
import o from "../../images/project/15.webp";
import p from "../../images/project/16.webp";
import q from "../../images/project/17.webp";
import r from "../../images/project/18.webp";
import aa from "../../images/project/19.webp";
import medkart from "../../images/project/medkart.webp";
import undisclosed from "../../images/project/undisclosed.webp";
import lock from "../../images/project/lock.webp";
import scikiq from "../../images/project/scikiq.webp";
import preSnap from "../../images/project/snap1.webp";
import hr from "../../images/project/hr.webp";
import sumanth from "../../images/project/preSnap.webp";
import z from "../../images/project/99.jpg";
import y from "../../images/project/100.jpg";

const options = {
  loop: true,
  margin: 20,
  nav: true,
  responsiveClass: true,
  autoplay: true,
  navText: [
    "<i class='fa fa-angle-left'></i> Prev",
    "Next <i class='fa fa-angle-right'></i>",
  ],
  smartSpeed: 2000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1,
    },
    300: {
      items: 2,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  },
};

let Credential = () => {
  return (
    <>
      <div className="container pt-100 animate__animated animate__fadeInUp animate__delay-5s animate__slow	2s">
        <div className="row justify-content-center">
          <div className="col-xl-8 text-center">
            <div className="section-header mb-50">
              <h4 className="section-title mb-25">
                <span>
                  <img src={shape1} className="mr-10" alt="" />
                </span>
                Credentials
                <span>
                  <img src={shape2} className="ml-10" alt="" />
                </span>
              </h4>
            </div>
          </div>
        </div>

        <OwlCarousel {...options} className="owl-theme">
          <div className="cardCase">
            <img src={z} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>One of India's Largest Freshness & Hygiene Brands</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={y} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>
                  Deep-Tech On-Demand Liquidity Platform designed for Bharat
                </p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={q} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>One of India's Largest Freshness & Hygiene Brands</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={aa} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>
                  Industrial Analytics company delivering Industry 4.0 benefits
                  to clients across Manufacturing Industry
                </p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={hr} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>A cloud based HR automation platform</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={sumanth} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>One of India's fastest growing Consumer Purchase Fintechs</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={medkart} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>Pioneer of Generic medicines retailing in India</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={o} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>Internet First Fashion Jewellery Brand</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={p} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>
                  Industrial Analytics company delivering Industry 4.0 benefits
                  to clients across Manufacturing Industry
                </p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={n} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>One of India's Largest Freshness & Hygiene Brands</p>
              </div>
            </div>
          </div>
          <div className="cardCase">
            <img src={r} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>Consumer Electronics focused vertical marketplace</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={preSnap} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>One of India's fastest growing Consumer Purchase Fintechs</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={a} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>End-to-end Android set-top-box software provider</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={lock} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>
                  Proptech Focused on Residential & commercial property
                  management
                </p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={b} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>
                  Loan comparison and other banking/financial services provider
                </p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={c} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>
                  Crowd sourced movie review and online entertainment analytics
                  platform
                </p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={g} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>Internet First Fashion Jewellery Brand</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={d} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>One of India's Largest Freshness & Hygiene Brands</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={e} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>
                  one of the fastest growing Rooftop and ground mounted Solar
                  EPC company
                </p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={f} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>
                  one of the fastest growing Rooftop and ground mounted Solar
                  EPC company
                </p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={h} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>One of India's Largest Freshness & Hygiene Brands</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={i} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>
                  Industrial Analytics company delivering Industry 4.0 benefits
                  to clients across Manufacturing Industry
                </p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={j} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>Provider of O & M services for solar power plant</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={l} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>Internet First Fashion Jewellery Brand</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={scikiq} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>
                  An Emerging Tech company helping organisations Acquire,
                  Discover and Extract value out of their Data using AI/ML &
                  Computer
                </p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={k} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>Financial Modelling Project</p>
              </div>
            </div>
          </div>

          <div className="cardCase">
            <img src={m} alt="Avatar" className="image" />
            <div className="overlay1">
              <div className="text1">
                <p>Financial modelling project </p>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </>
  );
};

export default Credential;
