import React from "react";
import a from "../../images/about/me.png";
import b from "../../images/about/MMedkart.png";
import c from "../../images/about/pru.png";
import d from "../../images/about/alk.png";
import e from "../../images/about/ins.png";
import f from "../../images/about/rad.png";

let Medkart = () => {
  return (
    <>
      <div className="container my-5">
        <div className="roundBorder">
          <div className="row">
            <div className="col-12 mb-5">
              <h2 className="titleServ">
                Medkart – A claimant to the 'most profitable Indian generic
                pharmacy chain' crown
              </h2>
              <br />
              <div className="lineServ"></div>
            </div>
            <div className="col-lg-6 mt-20">
              <div className="caseImg">
                <img src={a} alt="" />
              </div>
            </div>
            <div className="col-lg-6 mt-20">
              <div class="accordion" id="accordionExample">
                {/* <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <span>OUR THESIS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>
                          <b>Founding Team</b> – Average relevant experience of
                          15+ years with global MNCs in senior leadership levels
                        </p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">
                          <b>Landscape</b> – 90% of companies were Point
                          solutions providing siloed analytics as compared to
                          Medkart’s integrated global product
                        </p>
                      </div>
                      <div className="oneee">
                        <p>
                          <b>Proof of Concept</b> – Multiple Repeat Sales with
                          one of the Largest Aviation OEMs
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <span>INVESTORS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <a href="https://prudentim.com/">
                          <img src={c} alt="" width="250px" height="150px" />
                        </a>
                        <a href="https://www.insitorpartners.com/">
                          <img src={e} alt="" width="250px" height="250px" />
                        </a>
                      </div>
                      <br />
                      <div className="twooo">
                        <a href="https://www.alkemivp.com/">
                          <img src={d} alt="" width="250px" height="150px" />
                        </a>
                        <a href="https://radixcap.in/">
                          <img src={f} alt="" width="250px" height="150px" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span>CURRENT STATUS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>
                          <b>Leaping ahead through Innovation</b> – Developed 6
                          APPs, delivering quantifiable ROIs through Outcome
                          focused, Simple to configure Use-cases
                        </p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">
                          <b>Gaining Traction</b> – From One large Client to 8
                          Large clients, Medkart’s innovations are driving rapid
                          adoptions
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-12 mt-80">
              <h2 className="titleServ">
                Looking beyond the wave (with an eye on fundamentals)
              </h2>
              <br />
              <div className="lineServ"></div>
            </div>
            <div class="flip-box-container">
              <div class="flip-box">
                <div class="flip-box-inner">
                  <div class="flip-box-front">
                    <h3>Wave of Online Pharmacy – End of 2020</h3>
                    {/* <p>Front content goes here</p> */}
                  </div>
                  <div class="flip-box-back">
                    <ol>
                      <li>
                        <b>Online Pharmacy</b> wave due to ease of convenience.
                      </li>
                      <li>
                        <b>No thesis around Generic Pharmacy - </b>Nobody
                        changes their prescribed medicine brands in India.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>

              <div class="flip-box">
                <div class="flip-box-inner">
                  <div class="flip-box-front">
                    <h3>Radix’s Fundamental Approach</h3>
                    {/* <p>Front content goes here</p> */}
                  </div>
                  <div class="flip-box-back">
                    <ol>
                      <li>
                        India will be disrupted by{" "}
                        <b>generic wave similar to USA.</b>
                      </li>
                      <li>
                        Generic pharmacy is a substitution business and not a
                        distribution business like regular pharmacy.{" "}
                        <b>Offline model was the way to go!</b>
                      </li>
                      {/* <li>
                        Financing{" "}
                        <b>specific purchases of healthy customers </b>
                        is the key through digital play.
                      </li>
                      <li>
                        <b>Powering merchant will be the key </b>and it has to
                        be beyond Amazon & Flipkart.
                      </li> */}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-80 mb-30">
            <h2 className="titleServ">
              Medkart - A claimant to the ”most profitable Indian pharmacy
              chain” crown
            </h2>
            <br />
            <div className="lineServ"></div>
          </div>

          <div className="content">
            <div className="row">
              <div className="col-md-6">
                <img src={b} alt="Snapmint Image" className="img-fluid" />
              </div>
              <div className="col-md-6">
                <div className="additional-info2">
                  <h1 class="abc">
                    <strong>Medkart Today</strong>
                    <div className="lineServ"></div>
                  </h1>
                  <br />
                  <h1>
                    <strong>3x</strong>
                  </h1>
                  <h5>
                    Growth in Revenue
                    <br />
                    (store mature in 12-15 months)
                  </h5>
                  <br />
                  <h1>
                    <strong>4x</strong>
                  </h1>
                  <h5>Growth in number of Stores</h5>
                  <br />
                  <br />
                  {/* <h1>
                    <strong>A Minicorn!</strong>
                  </h1> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center my-5">
          <a href="/HR-one">
            <button className="btnArrow">
              <i className="fa-solid fa-arrow-left"></i> HROne
            </button>
          </a>
          &nbsp;&nbsp;
          <a href="/Sunsure">
            <button className="btnArrow">
              Sunsure <i className="fa-solid fa-arrow-right"></i>
            </button>
          </a>
        </div>
      </div>
    </>
  );
};
export default Medkart;
