import React from "react";

import sukhhi from "../../images/about/sukhee.webp";

let Sukhhi = () => {
  return (
    <>
      <div className="container my-5">
        <div className="roundBorder">
          <div className="row">
            <div className="col-12 mb-5">
              <h2 className="titleServ">
                Sukkhi – Aspirational Brand from masses to classes
              </h2>
              <br />
              <div className="lineServ"></div>
            </div>
            <div className="col-lg-6 mt-20">
              <div className="caseImg">
                <img src={sukhhi} alt="" />
              </div>
            </div>
            <div className="col-lg-6 mt-20">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <span>OUR THESIS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>
                          <b>Founding Team</b> – Product Expertise, on-ground
                          market intelligence
                        </p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">
                          <b>Landscape</b> – Very large but fragmented market,
                          Sukkhi being one of the two organized players
                        </p>
                      </div>
                      <div className="oneee">
                        <p>
                          <b>Proof of Concept</b> – One of the largest online
                          brand, 1.5 Mn+ orders, encouraging repeat sales trend
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span>INVESTORS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>Carpediem Private Equity</p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">Canada Based Family Office</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span>CURRENT STATUS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>
                          <b>Omni Channel Brand </b> – Rapid Offline expansion
                          with touch points being added at an exponential pace
                        </p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">
                          <b>New Product Categories</b> – Successful launch of
                          new product categories
                        </p>
                      </div>
                      <div className="oneee">
                        <p>
                          <b>Rapid Expansion Mode </b> – Growth mode across
                          areas such as team, infrastructure, scale and
                          visibility
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center my-5">
          <a href="/snapmint">
            <button className="btnArrow">
              <i className="fa-solid fa-arrow-left"></i> Snapmint
            </button>
          </a>
          &nbsp;&nbsp;
          <a href="/freshee">
            <button className="btnArrow">
              Freshee <i className="fa-solid fa-arrow-right"></i>
            </button>
          </a>
        </div>
      </div>
    </>
  );
};

export default Sukhhi;
