import React from 'react';
import { useHistory } from "react-router-dom";


let Thankyou = () =>{

    let history = useHistory();

    setTimeout(function() {
        history.goBack();
    }, 3000);

    return(
        <>
        <section id='thankYou' className='mt-50 mb-50'>
            <div className="container">
                <div className="cardThankYou">
                    <h2>THANK YOU!</h2>
                    <br />
                    <center>
                        <div className="checkBox">
                            <i class="fa-solid fa-check"></i>
                        </div>
                    </center>
                    <p>We'll connect with you shortly...</p>
                </div>
            </div>
        </section>
        </>
    )
}

export default Thankyou;