import React from "react";

import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

import p1 from "../../images/about/p1.webp";
import p2 from "../../images/about/p2.webp";
import p3 from "../../images/about/p3.webp";
import p4 from "../../images/about/p4.webp";

let Principle = () => {
  return (
    <>
      <section className="about-area pt-50 animate__animated animate__fadeInUp animate__delay-5s animate__slow	2s">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-12 pl-80">
              <div className="section-header mb-40">
                <div className="section-header text-center mb-50">
                  <h4 className="section-title mb-25">
                    <span>
                      <img src={shape1} className="mr-10" alt="" />
                    </span>
                    Our Principle
                    <span>
                      <img src={shape2} className="ml-10" alt="" />
                    </span>
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-lg-3" id="width50">
              <img src={p1} id="onHoverTop" className="prinCII" alt="" />
            </div>
            <div className="col-lg-3" id="width50">
              <img src={p2} id="onHoverTop" className="prinCII" alt="" />
            </div>
            <div className="col-lg-3" id="width50">
              <img src={p3} id="onHoverTop" className="prinCII" alt="" />
            </div>
            <div className="col-lg-3" id="width50">
              <img src={p4} id="onHoverTop" className="prinCII" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Principle;
