import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import AboutUs from './Components/About/AboutUs';
import Team from './Components/About/Team';
import Career from './Components/Career/Career';
import Case from './Components/Case/Case';
import Freshee from './Components/Case/Freshee';
import Neewee from './Components/Case/Neewee';
import Snapmint from './Components/Case/Snapmint';
import Sukhhi from './Components/Case/Sukhhi';
import Contactus from './Components/Contact/Contactus';
import Cren from './Components/Credentials/Cren';
import Home from './Components/Home/Home';
import ScrollToTop from './Components/ScrollToTop';
import Services from './Components/Services/Services';
import Abhishek from './Components/Team/Abhishek';
import Akash from './Components/Team/Akash';
import Ashish from './Components/Team/Ashish';
import Mahesh from './Components/Team/Mahesh';
import Maya from './Components/Team/Maya';
import Thankyou from './Components/Thankyou/Thankyou';
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Vignesh from './Components/Team/Vignesh';
import HRone from './Components/Case/HRone';
import Sunsure from './Components/Case/Sunsure';
import Medkart from './Components/Case/Medkart';


function App() {
  return (
    <>
    <Router>
      <ScrollToTop/>
      <Header/>
        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route exact path="/contact_us">
            <Contactus/>
          </Route>
          <Route exact path="/about_us">
            <AboutUs/>
          </Route>
          <Route exact path="/credential">
            <Cren/>
          </Route>
          <Route exact path="/career">
            <Career/>
          </Route>
          <Route exact path="/service">
            <Services/>
          </Route>
          <Route exact path="/case_study">
            <Case/>
          </Route>
          <Route exact path="/neewee">
            <Neewee/>
          </Route>
          <Route exact path="/freshee">
            <Freshee/>
          </Route>
          <Route exact path="/sukhhi">
            <Sukhhi/>
          </Route>
          <Route exact path="/HR-one">
            <HRone/>
          </Route>
          <Route exact path="/Sunsure">
            <Sunsure/>
          </Route>
          <Route exact path="/Medkart">
            <Medkart/>
          </Route>
          <Route exact path="/team">
            <Team/>
          </Route>
          <Route exact path="/abhishek">
            <Abhishek/>
          </Route>
          <Route exact path="/maya">
            <Maya/>
          </Route>
          <Route exact path="/akash">
            <Akash/>
          </Route>
          <Route exact path="/mahesh">
            <Mahesh/>
          </Route>
          <Route exact path="/vignesh">
            <Vignesh/>
          </Route>
          <Route exact path="/ashish">
            <Ashish/>
          </Route>
          <Route exact path="/snapmint">
            <Snapmint/>
          </Route>
          <Route exact path="/thankyou">
            <Thankyou/>
          </Route>
        </Switch>
        <Footer/>
    </Router>
    </>
  );
}

export default App;
