import React from "react";

import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

import about1 from "../../images/bg/about-bg-2.webp";

let Whoweare = () => {
  return (
    <>
      <section className="about-area pt-50 animate__animated animate__fadeInUp animate__delay-1s animate__slow	2s">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-6 pl-50">
              <div className="section-header mb-40">
                <div className="section-header mb-50">
                  <h4 className="section-title mb-25">
                    <span>
                      <img src={shape1} className="mr-10" alt="" />
                    </span>
                    Who We Are
                    <span>
                      <img src={shape2} className="ml-10" alt="" />
                    </span>
                  </h4>
                </div>
                <p className="pPpP">
                  Our business philosophy is centered around three facets- Honesty, Quality and Value. Our highly selective engagements are predicated on building deep, transformational relationships with both the investors and founders we work with.  <br />
                  Banking, hence, is not simply a transactional business for us. Our eye for potential value creating opportunities, steadfast belief in honest advisory and deep domain expertise make us a class apart.
                  <br />Our approach ensures that only the most interesting deals with solid fundamentals reach the investor. <br />
                  <strong>
                  | Augmenting Value |
                  </strong>
                </p>
              </div>
            </div>

            <div className="col-xl-6 pr-50">
              <div className="about__bg" data-tilt data-tilt-perspective="3000">
                <img src={about1} className="prinCII" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Whoweare;
