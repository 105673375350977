import React from "react";

import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";
import b from "../../images/team/b.webp";
import c from "../../images/team/c.webp";
import a from "../../images/team/a.webp";
import e from "../../images/team/e.webp";
import f from "../../images/team/f.webp";

import ea from "../../images/team/jay.webp";

let Vignesh = () => {
  return (
    <>
      <section className="about-area pb-50">
        <div className="section-header">
          <div className="section-header text-center">
            <img src={ea} className="w100" alt="" />
          </div>
        </div>
        <div className="container">
          <div className="tab-pane mt-50" id="maya">
            <div className="row">
              <div className="col-md-12">
                <div className="profile-details">
                  <h3>The Navigator</h3>
                  <hr />
                  <p>
                    Vignesh has been in the financial services industry for over
                    5 years, during which he worked with clients in various
                    sectors including Telecom, Consumer Food & Beverage,
                    Industrials & Chemicals, BFSI, and Real Estate &
                    Infrastructure. 
                  </p>
                  <br />
                  <p>
                  He worked in the Corporate Finance division
                    of Duff & Phelps (rebranded as Kroll) and Equirus before
                    joining Radix.
                  </p>
                  <br />
                  {/* <p>
                    Apart from being a banker in life, Jay is passionate about
                    piano and a Big Manchester United supporter.
                  </p>
                  <br />
                  <p>
                    Jay is a qualified Chartered Accountant and has cleared
                    level II of CFA (U.S.A).
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area pt-40 pb-50 animate__animated animate__fadeInUp animate__delay-3s animate__slow	2s">
        <div className="container">
          <div className="section-header mb-100">
            <div className="section-header text-center mb-50">
              <h4 className="section-title mb-25">
                <span>
                  <img src={shape1} className="mr-10" alt="" />
                </span>
                Other Members
                <span>
                  <img src={shape2} className="ml-10" alt="" />
                </span>
              </h4>
            </div>
          </div>

          <div className="d-flex">
            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/abhishek">
                  <img src={b} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Abhishek Taparia <br />
                    <span>
                      <small>(Co-Founder & CEO)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/maya">
                  <img src={c} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Maya Taparia <br />
                    <span>
                      <small>(Co-Founder & COO)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/mahesh">
                  <img src={a} className="img-responsive" alt="profile" />
                  <h6 className="namePosition">
                    Mahesh T.D. <br />
                    <span>
                      <small>(VP)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/akash">
                  <img src={e} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                  Akash Bagul <br />{" "}
                    <span>
                      <small>(Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/ashish">
                  <img src={f} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Ashish Chacko <br />{" "}
                    <span>
                      <small>(Senior Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Vignesh;
