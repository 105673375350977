import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

import a from "../../images/other/a.webp";
import b from "../../images/other/b.webp";
import c from "../../images/other/c.webp";
import d from "../../images/other/d.webp";
import e from "../../images/other/e.webp";
import f from "../../images/other/f.webp";
import g from "../../images/other/g.webp";
import h from "../../images/other/h.webp";
import zz from "../../images/other/299.webp";
import yy from "../../images/other/298.webp";
import xx from "../../images/other/296.webp";
import ww from "../../images/other/295.webp";
import vv from "../../images/other/294.webp";
import uu from "../../images/other/284.webp";


const options = {
  loop: true,
  margin: 20,
  nav: true,
  responsiveClass: true,
  autoplay: true,
  navText: [
    "<i class='fa fa-angle-left'></i> Prev",
    "Next <i class='fa fa-angle-right'></i>",
  ],
  smartSpeed: 4000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1,
    },
    300: {
      items: 2,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  },
};

let Media = () => {
  return (
    <>
      <div className="container pt-100 animate__animated animate__fadeInUp animate__delay-8s animate__slow	2s">
        <div className="row justify-content-center">
          <div className="col-xl-8 text-center">
            <div className="section-header mb-50">
              <h4 className="section-title mb-25">
                <span>
                  <img src={shape1} className="mr-10" alt="" />
                </span>
                Media Coverage
                <span>
                  <img src={shape2} className="ml-10" alt="" />
                </span>
              </h4>
            </div>
          </div>
        </div>

        <OwlCarousel {...options} className="owl-theme">
          <div>
            <a
              href="https://www.livemint.com/Companies/0t12mvin0J8qM23bR6KKLN/Edelweiss-Private-Equity-invests-in-Freshee.html"
              target="_blank"
              rel="noreferrer"
            >
              <img src={a} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>
          <div>
            <a
              href="https://inc42.com/buzz/fintech-startup-snapmint-raises-9-mn-to-launch-innovative-bnpl-products/#:~:text=Mumbai-based%20fintech%20startup%2C%20Snapmint%20has%20raised%20%249%20Mn,Seth%2C%20CEO%20and%20CIO%20of%20Prudent%20Investment%20Managers"
              target="_blank"
              rel="noreferrer"
            >
              <img src={h} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>

          <div>
            <a
              href="https://economictimes.indiatimes.com/industry/healthcare/biotech/pharmaceuticals/generic-drugs-retail-chain-medkart-raises-rs-40-crore-in-series-a-round/articleshow/87871013.cms"
              target="_blank"
              rel="noreferrer"
            >
              <img src={g} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>

          <div>
            <a
              href="https://www.livemint.com/Companies/0t12mvin0J8qM23bR6KKLN/Edelweiss-Private-Equity-invests-in-Freshee.html"
              target="_blank"
              rel="noreferrer"
            >
              <img src={a} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>

          <div>
            <a
              href="http://bwdisrupt.businessworld.in/article/Fashion-Jewellery-Brand-Sukkhi-Raises-7M-from-Carpediem-Capital-and-Duane-Park/29-10-2018-163067/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={c} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>

          <div>
            <a
              href="https://inc42.com/buzz/fintech-startup-snapmint-raises-9-mn-to-launch-innovative-bnpl-products/#:~:text=Mumbai-based%20fintech%20startup%2C%20Snapmint%20has%20raised%20%249%20Mn,Seth%2C%20CEO%20and%20CIO%20of%20Prudent%20Investment%20Managers."
              target="_blank"
              rel="noreferrer"
            >
              <img src={c} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>

          <div>
            <a
              href="https://www.thehindubusinessline.com/companies/freshee-raises-funds-from-edelweiss-private-equity/article8701447.ece"
              target="_blank"
              rel="noreferrer"
            >
              <img src={d} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>
          <div>
            <a
              href="https://www.vccircle.com/exclusive-connectabank-gets-second-round-angel-funding/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={e} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>
          <div>
            <a
              href="https://www.vccircle.com/crowdsourced-movie-review-site-clapsnslaps-raises-angel-funding/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={f} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>
          <div>
            <a
              href="https://www.expresshealthcare.in/news/medkart-raises-inr-40-crs-co-led-by-alkemi-venture-partners-and-insitor-partners/432239/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={zz} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>
          <div>
            <a
              href="https://www.business-standard.com/article/companies/medkart-raises-rs-40-cr-in-series-a-co-led-by-alkemi-insitor-partners-121112300726_1.html"
              target="_blank"
              rel="noreferrer"
            >
              <img src={yy} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>
          <div>
            <a
              href="https://www.vccircle.com/hronesecures-series-a-funding-from-insitor-and-prudent"
              target="_blank"
              rel="noreferrer"
            >
              <img src={xx} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>
          <div>
            <a
              href="https://indianstartupnews.com/news/hr-tech-startup-hrone-raises-4m-in-a-series-a-round/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={ww} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>
          <div>
            <a
              href="https://inc42.com/buzz/hrone-secures-funding-to-help-businesses-automate-hr-operations/?itm_source=inc42-sponsored-content&itm_medium=website&itm_campaign=sponsored-content"
              target="_blank"
              rel="noreferrer"
            >
              <img src={vv} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>
          <div>
            <a
              href="https://www.expresshealthcare.in/news/medkart-raises-inr-40-crs-co-led-by-alkemi-venture-partners-and-insitor-partners/432239/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={uu} id="carouselHeight" alt="image_not-found" />
            </a>
          </div>
        </OwlCarousel>
      </div>
    </>
  );
};

export default Media;
