import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

import rishab from "../../images/review/rishab.webp";
import joshi from "../../images/review/joshi.webp";
import nitish from "../../images/review/nitish.webp";
import vaibhav from "../../images/review/vaibhav.webp";
import bhavesh from "../../images/review/bhavesh.webp";
import vimal from "../../images/review/vimal.webp";
import abhishek from "../../images/review/abhishek.webp";
import seth from "../../images/review/seth.webp";
import ankit from "../../images/review/ankit.webp";
import nalin from "../../images/review/nalin.webp";



const options = {
  loop: true,
  margin: 20,
  nav: true,
  responsiveClass: true,
  autoplay: true,
  navText: ["<i class='fa fa-angle-left'></i> Prev","Next <i class='fa fa-angle-right'></i>"],
  smartSpeed: 5000,
  autoplayHoverPause:true,
  responsive: {
    0: {
      items: 1,
    },
    300: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

let Testimonial = () => {
  return (
    <>
      <div className="testimonial-area pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="section-header mb-25 animate__animated animate__fadeInUp animate__delay-1s">
                <h4 className="section-title mb-25">
                  <span>
                    <img src={shape1} className="mr-10" alt="" />
                  </span>
                  Testimonial
                  <span>
                    <img src={shape2} className="ml-10" alt="" />
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <OwlCarousel {...options} className="owl-theme animate__animated animate__fadeInUp animate__delay-2s animate__slow	2s">
        <div className="pb-20 pt-20">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="testimonial">
                    <div className="testimonial-item">
                      <div className="testImgF">
                        <img src={joshi} alt="" />
                      </div>

                      <div className="testimonial-item__content" id="paddingNO">
                        <h4>As part of the overall fund raise process, it was always felt as Radix was part of the founding team.</h4>
                        <p>
                         

They completely owned the transaction from reaching out to closure, which was done in a record time. Their inputs on overall business model, Sales approach and other financial terms were highly appreciated. 

Radix will continue to be associated with Neewee in multiple capacities to help us grow further.</p>
                        <h4 className="name mt-40">
                          Suyog Joshi <br />
                          <span className="designation">
                            Co-Founder & CEO <b>(Neewee)</b>
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


    

          <div className="pb-20 pt-20">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="testimonial">
                    <div className="testimonial-item">
                      <div className="testImgF">
                        <img src={ankit} alt="" />
                      </div>

                      <div className="testimonial-item__content" id="paddingNO">
                      <h4> It has been an amazing journey of understanding our business better through the eyes of Radix. </h4>
                        <p>
                     

I have not seen any IB putting in the kind of effort to provide inputs to improve the business and see it through different metrices to vet and improve from different aspects. The constant push to evaluate and bring things up above and beyond our own expectations made us do the unthinkable on our own even before we had the funds had been committed. It seems raising funds was a small part of the overall effort that went in. I could not have thought of a better person to help us get to where we are today and being with us through the worst of times.

Thanks to team Radix who have been an outstanding support on our journey till now and way forward.   </p>
                        <h4 className="name mt-40">
                          Ankur Agarwal <br />
                          <span className="designation">
                            Co-Founder & CEO <b>(Medkart)</b>
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb-20 pt-20">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="testimonial">
                    <div className="testimonial-item">
                      <div className="testImgF">
                        <img src={nalin} alt="" />
                      </div>

                      <div className="testimonial-item__content" id="paddingNO">
                      <h4>      The experience with Radix was like having a CFO and a cofounder by your side. </h4>

                        <p>
              
It was a great experience working with Radix Capital. Their unrelenting effort to work with the company during Covid for its Pre-series A raise and for its Series A raise, thereafter, was commendable. The experience with them was like having a CFO and a cofounder by your side. 
Their focus on always keeping the promoters first while the deal secondary really helped us get the best terms </p>
                        <h4 className="name mt-40">
                        Nalin Agrawal <br />
                          <span className="designation">
                            Co-Founder & CEO <b>(Snapmint)</b>
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb-20 pt-20">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="testimonial">
                    <div className="testimonial-item">
                      <div className="testImgF">
                        <img src={vimal} alt="" />
                      </div>

                      <div className="testimonial-item__content" id="paddingNO">
                      <h4>       Radix has been a key strength behind the Freshee Journey and growth. </h4>
  <p>
             
Radix’s dedicated efforts on investment processes and in-depth involvement in Freshee's journey has been a good source of energy for fuelling the Freshee growth. 

Team Radix is well educated and are bunch of knowledgeable professionals and works with high values.</p>
                        <h4 className="name mt-40">
                          Vimal Somani <br />
                          <span className="designation">
                            Founder & Chairman <b>(Freshee)</b>
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-20 pt-20">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="testimonial">
                    <div className="testimonial-item">
                      <div className="testImgF">
                        <img src={seth} alt="" />
                      </div>

                      <div className="testimonial-item__content" id="paddingNO">
                      <h4>           Radix has demonstrated ability to dive into next level of details and engage with investors to address queries and concerns</h4>
      <p>
         
Radix has showed us multiple deals, one of which we have recently invested. During the interaction with them , what we have liked most about them is the detailed understanding of the businesses that they have showcased to us.  Abhishek’s depth of knowledge about the business is as detailed if not more as those of the founders of the business. The team also has demonstrated its ability to dive into next level of details and engage with investors to address their queries and concerns. We have also found them fairly balanced in terms of proposing the structure and terms of the transactions so as to ensure a win win deal both for the promoters and the investors. </p>
                        <h4 className="name mt-40">
                          Prashasta Seth <br />
                          <span className="designation">
                            CEO <b>(IIFL Asset Management)</b>
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="pb-20 pt-20">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="testimonial">
                    <div className="testimonial-item">
                      <div className="testImgF">
                        <img src={vaibhav} alt="" />
                      </div>

                      <div className="testimonial-item__content" id="paddingNO">
                      <h4>    I have not come across more passionate people in the business.</h4>
  <p>
                  
Have known Radix Capital Advisors for the last 3 - 4 years, since we started looking at Freshee for investment. I must say that I have not come across more passionate people in the business. Radix gives their all for the businesses they choose to support / partner. Will highly recommend Radix to companies/promoters looking to raise their first institutional capital.</p>
                        <h4 className="name mt-40">
                          Vaibhav Patni <br />
                          <span className="designation">
                            Principal <b>(Edelweiss Private Equity)</b>
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="pb-20 pt-20">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="testimonial">
                    <div className="testimonial-item">
                      <div className="testImgF">
                        <img src={abhishek} alt="" />
                      </div>

                      <div className="testimonial-item__content" id="paddingNO">
                      <h4>Radix would never work with a company unless they are convinced about its long term potential</h4>   <p>
                      
Have known Radix for years now. I admire work ethics being built at Radix, as they would never work with a company unless they are convinced about its long term potential . Have seen Team spend significant time in understanding the company before accepting them as clients. My colleagues who meet Abhishek for the first time, often feel that he is part of the management team of the company he represents, as he is completely on top of the business and later are surprised to note that he is just the advisor. </p>
                        <h4 className="name mt-40">
                          Abhishek Poddar <br />
                          <span className="designation">
                            Director <b>(Banayan Tree Private Equity)</b>
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-20 pt-20">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="testimonial">
                    <div className="testimonial-item" id="testFlex">
                      <div className="testImgF">
                        <img src={rishab} alt="" />
                      </div>

                      <div className="testimonial-item__content" id="paddingNO">
                      <h4>       Radix Capital has been a preferred investment banking partner for us</h4>
      <p>
              
Radix Capital has been a preferred investment banking partner for us, the clear differentiator is the domain expertise they carry within the team. The team spends considerable amounts of time understanding the business before reaching out to us which helps us move swiftly on opportunities. Their approach of selectively identifying opportunities has helped us create a quality pipeline worth investing our time on. </p>
                        <h4 className="name mt-40">
                          Rishabh Siroya <br />
                          <span className="designation">
                            Senior Associate <b>(CarpeDiem Capital)</b>
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb-20 pt-20">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="testimonial">
                    <div className="testimonial-item">
                      <div className="testImgF">
                        <img src={nitish} alt="" />
                      </div>

                      <div className="testimonial-item__content" id="paddingNO">
                        <p>
                          Working with Radix Capital Advisors has been a
                          fantastic experience. Having them on board is as good
                          as adding on another Co-founder & Chief Strategy
                          Officer. In addition to being extremely professional
                          and ethical, goes above and beyond to deliver what's
                          best for the business, and given the depth of their
                          understanding and involvement with our business, it
                          almost felt like we were their only client and they
                          were working with us full-time.
                        </p>
                        <h4 className="name mt-40">
                          Nishith Shah <br />
                          <span className="designation">
                            Co-Founder & CSO <b>(18TH PARALLEL)</b>
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        
      
     
          <div className="pb-20 pt-20">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="testimonial">
                    <div className="testimonial-item">
                      <div className="testImgF">
                        <img src={bhavesh} alt="" />
                      </div>

                      <div className="testimonial-item__content" id="paddingNO">
                        <p>
                          Radix team had been our investment banking partner
                          for our first successful institutional raise. They
                          have been part of our overall growth and strategy
                          journey since over a year and a half now and has been
                          instrumental in getting us the right partner for our
                          growth journey. Their understanding of the consumer
                          industry and investor mindset has been a huge support
                          at all stages of the fund raise including due
                          diligence process and documentation negotiation. Their
                          understanding and team strength in the accounting
                          domain provides a one stop solution to your entire
                          deal making and closure requirements.
                        </p>
                        <h4 className="name mt-40">
                          Bhavesh Navlakha <br />
                          <span className="designation">
                            Founder & CEO <b>(Sukhhi)</b>
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

       
     
      
      
        </OwlCarousel>
      </div>
    </>
  );
};

export default Testimonial;
