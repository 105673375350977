import React from "react";
import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

import a from "../../images/team/a.webp";
import b from "../../images/team/b.webp";
import c from "../../images/team/c.webp";
import d from "../../images/team/d.webp";
import e from "../../images/team/e.webp";
import f from "../../images/team/f.webp";

let Team = () => {
  return (
    <>
      <div
        className="container  pt-50 animate__animated animate__fadeInUp animate__delay-4s animate__slow	2s "
        id="mobilePadding"
      >
        <div className="row justify-content-center">
          <div className="col-xl-8 text-center">
            <div className="section-header mb-50 ">
              <h4 className="section-title ">
                <span>
                  <img src={shape1} className="mr-10" alt="" />
                </span>
                Our Team
                <span>
                  <img src={shape2} className="ml-10" alt="" />
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="teamBond">
          <div className="teamCard">
            <img src={b} alt="not-found" />
            <a href="/abhishek">
              <div className="titlE">
                <h2>Abhishek Taparia</h2>
                <p>(Co-Founder & CEO)</p>
              </div>
            </a>
            <ul id="ul">
              <a
                href="https://www.linkedin.com/in/abhishek-taparia-1b32265/"
                target="_blank"
                rel="noreferrer"
              >
                <li id="li">
                  <i className="fab fa-linkedin"></i>
                </li>
              </a>
            </ul>
          </div>

          <div className="teamCard">
            <img src={c} alt="not-found" />
            <a href="/maya">
              <div className="titlE">
                <h2>Maya Taparia</h2>
                <p>(Co-Founder & COO)</p>
              </div>
            </a>
            <ul id="ul">
              <a
                href="https://www.linkedin.com/in/maya-taparia-8507ab137/"
                target="_blank"
                rel="noreferrer"
              >
                <li id="li">
                  <i className="fab fa-linkedin"></i>
                </li>
              </a>
            </ul>
          </div>

          <div className="teamCard">
            <img src={a} alt="not-found" />
            <a href="/mahesh">
              <div className="titlE">
                <h2>Mahesh T.D.</h2>
                <p>VP</p>
              </div>
            </a>
            <ul id="ul">
              <a href="https://www.linkedin.com/in/mahesh-triplicane-damodaran-315190a/">
                <li id="li">
                  <i className="fab fa-linkedin"></i>
                </li>
              </a>
            </ul>
          </div>

          <div className="teamCard">
            <img src={f} alt="not-found" />
            <a href="/ashish">
              <div className="titlE">
                <h2>Ashish Chacko</h2>
                <p>Senior Analyst</p>
              </div>
            </a>
            <ul id="ul">
              <a
                href="https://www.linkedin.com/in/ashishchacko/"
                target="_blank"
                rel="noreferrer"
              >
                <li id="li">
                  <i className="fab fa-linkedin"></i>
                </li>
              </a>
            </ul>
          </div>

          <div className="teamCard">
            <img src={d} alt="not-found" />
            <a href="/vignesh">
              <div className="titlE">
                <h2>Vignesh Ramanan</h2>
                <p>Analyst</p>
              </div>
            </a>
            <ul id="ul">
              <a
                href="https://www.linkedin.com/in/vigneshramanan/"
                target="_blank"
                rel="noreferrer"
              >
                <li id="li">
                  <i className="fab fa-linkedin"></i>
                </li>
              </a>
            </ul>
          </div>

          <div className="teamCard">
            <img src={e} alt="not-found" />
            <a href="/akash">
              <div className="titlE">
                <h2>Akash Bagul</h2>
                <p>Analyst</p>
              </div>
            </a>
            <ul id="ul">
              <a
                href="https://www.linkedin.com/in/akashbagul90/"
                target="_blank"
                rel="noreferrer"
              >
                <li id="li">
                  <i className="fab fa-linkedin"></i>
                </li>
              </a>
            </ul>
          </div>

          
        </div>
      </div>
    </>
  );
};

export default Team;
