import React from "react";
import {Helmet} from "react-helmet";
import Case from "./Case";
import Contact from "./Contact";
import Credential from "./Credential";
import Hero from "./Hero";
import Media from "./Media";
import Team from "./Team";
import Testimonial from "./Testimonial";

let Home = () => {
  return (
    <>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Radixcap | Augmenting Value</title>
        <meta name="format-detection" content="telephone=no" />
        <link rel="canonical" href="http://radixcap.in/" />
      </Helmet>


      <Hero />
      <Testimonial />
      <Team />
      <Credential />
      <Case />
      <Media />
      <Contact />
    </>
  );
};
export default Home;
