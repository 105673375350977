import React from "react";

import ab from "../../images/team/z.jpg";
import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

import a from "../../images/team/a.webp";
import c from "../../images/team/c.webp";
import d from "../../images/team/d.webp";
import e from "../../images/team/e.webp";
import b from "../../images/team/b.webp";

let Ashish = () => {
  return (
    <>
      <section className="about-area pb-50">
        <div className="section-header">
          <div className="section-header text-center">
            <img src={ab} className="w100" alt="" />
          </div>
        </div>
        <div className="container">
          <div className="tab-pane mt-50" id="abhishek">
            <div className="row">
              <div className="col-md-12">
                <div className="profile-details">
                  <h3>The Philomath</h3>
                  <hr />
                  <p>
                    Ashish’s inclination to the start-up world began in
                    Australia where he did his Masters of Finance from Bond
                    University. During his time there, Ashish was part of the
                    Finance team of a digital marketing start up. His penchant
                    for Finance, however, began much earlier during his time at
                    Christ University, Bangalore.
                  </p>
                  <br />
                  <p>
                    Radix presented the perfect amalgam of Finance and
                    Entrepreneurship to Ashish. He believes his exposure to
                    founders through Radix stokes his own entrepreneurial
                    spirit.
                  </p>
                  <br />
                  <p>
                    Ashish represented Bond University in the CFA Research
                    Challenge -Security Appraisal of Telstra Corporation Ltd.
                    ASX: TLS. He is also an avid squash player and an F1
                    enthusiast.
                  </p>
                  <br />
                  <p>Ashish is a CFA Level II candidate.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area pt-40 pb-50 animate__animated animate__fadeInUp animate__delay-3s animate__slow	2s">
        <div className="container">
          <div className="section-header mb-100">
            <div className="section-header text-center mb-50">
              <h4 className="section-title mb-25">
                <span>
                  <img src={shape1} className="mr-10" alt="" />
                </span>
                Other Members
                <span>
                  <img src={shape2} className="ml-10" alt="" />
                </span>
              </h4>
            </div>
          </div>

          <div className="d-flex">
            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/abhishek">
                  <img src={b} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Abhishek Taparia <br />
                    <span>
                      <small>(Co-Founder & CEO)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/maya">
                  <img src={c} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Maya Taparia <br />
                    <span>
                      <small>(Co-Founder & COO)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/mahesh">
                  <img src={a} className="img-responsive" alt="profile" />
                  <h6 className="namePosition">
                    Mahesh T.D. <br />
                    <span>
                      <small>(VP)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/akash">
                  <img src={e} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                  Akash Bagul <br />{" "}
                    <span>
                      <small>(Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/vignesh">
                  <img src={d} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                  Vignesh Ramanan <br />{" "}
                    <span>
                      <small>(Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ashish;
