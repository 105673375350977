import React from "react";
import about from "../../images/bg/about-bg-1.webp";
import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

let About = () => {
  return (
    <>
      <section className="about-area pt-50 pb-10 animate__animated animate__fadeInUp animate__delay-3s animate__slow	2s">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-6 pr-50">
              <div className="about__bg" data-tilt data-tilt-perspective="3000">
                <img src={about} className="prinCII" alt="" />
              </div>
            </div>
            <div className="col-xl-6 pl-50">
              <div className="section-header">
                <div className="section-header mb-20">
                  <h4 className="section-title">
                    <span>
                      <img src={shape1} className="mr-10" alt="" />
                    </span>
                    Our Identity
                    <span>
                      <img src={shape2} className="ml-10" alt="" />
                    </span>
                  </h4>
                </div>
                <p className="pPpP">
                  Our logo, rising sun with growing stages, is a clear
                  reflection of our thought process and our approach towards our
                  work. <br />
                  In mathematics <b>“Radix”</b> means a number that is base to
                  the entire number system, in biology <b>“Radix”</b> is defined
                  as the root or point of origin. In other words, Radix is the
                  most fundamental part and yet has the capacity to build the
                  entire domain. <br /> Radix Capital Advisors is inspired by
                  the same concept –{" "}
                  <strong>
                    Most basic yet the most valuable partner in value
                    augmentation
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default About;
