import React from "react";

import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

import a from "../../images/team/a.webp";
import b from "../../images/team/b.webp";
import d from "../../images/team/d.webp";
import e from "../../images/team/e.webp";
import f from "../../images/team/f.webp";

import bc from "../../images/team/maya.webp";

let Maya = () => {
  return (
    <>
      <section className="about-area pb-50">
        <div className="section-header">
          <div className="section-header text-center">
            <img src={bc} className="w100" alt="" />
          </div>
        </div>
        <div className="container">
          <div className="tab-pane mt-50" id="maya">
            <div className="row">
              <div className="col-md-12">
                <div className="profile-details">
                  <h3>The Backbone</h3>
                  <hr />
                  <b>CA Rank holder | Angel Investor</b>
                  <br />
                  <br />
                  <p>Maya believes in this verse from Bhagwat Gita.</p>
                  <br />
                  <p>“karmanye vadhikaras te, ma phalesu kadachana”</p>
                  <br />
                  <p>
                    She believes in giving 100% to her Karmas without expecting
                    results, never let the fruits be the motive of doing karmas.
                  </p>
                  <br />
                  <p>
                    Maya is passionate about Investment banking as it provides
                    an opportunity to learn a variety of sectors, diversified
                    experience, opportunity to interact with people of multiple
                    skill set, and improves multitasking ability.
                  </p>
                  <br />
                  <p>
                    Prior to Co-founding Radix, She has worked with
                    Multinationals like Bosch and Sodexo. She has more than a
                    decade of experience in various fields of finance including
                    treasury, planning and analysis, financial control.
                  </p>
                  <br />
                  <p>
                    Hailing from a small town in Didwana, she not only cleared
                    CA but secured 25th rank in India . She featured in the
                    merit list of the board examination, and is a gold medallist
                    in her master degree also. Maya is a big believer in the
                    Bharat Story and is a strong backer of the idea that small
                    towners & women will lead the Indian growth stories!
                  </p>
                  <br />
                  <p>
                    She has served as an Independent Director on the boards of
                    several companies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area pt-40 pb-50 animate__animated animate__fadeInUp animate__delay-3s animate__slow	2s">
        <div className="container">
          <div className="section-header mb-100">
            <div className="section-header text-center mb-50">
              <h4 className="section-title mb-25">
                <span>
                  <img src={shape1} className="mr-10" alt="" />
                </span>
                Other Members
                <span>
                  <img src={shape2} className="ml-10" alt="" />
                </span>
              </h4>
            </div>
          </div>

          <div className="d-flex">
            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/abhishek">
                  <img src={b} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Abhishek Taparia <br />
                    <span>
                      <small>(Co-Founder & CEO)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/mahesh">
                  <img src={a} className="img-responsive" alt="profile" />
                  <h6 className="namePosition">
                    Mahesh T.D. <br />
                    <span>
                      <small>(VP)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/akash">
                  <img src={e} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                  Akash Bagul <br />{" "}
                    <span>
                      <small>(Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/vignesh">
                  <img src={d} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                  Vignesh Ramanan <br />{" "}
                    <span>
                      <small>(Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/ashish">
                  <img src={f} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Ashish Chacko <br />{" "}
                    <span>
                      <small>(Senior Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>


          </div>
        </div>
      </section>
    </>
  );
};

export default Maya;
