import React from "react";
import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

import a from "../../images/team/a.webp";
import b from "../../images/team/b.webp";
import c from "../../images/team/c.webp";
import d from "../../images/team/d.webp";
import f from "../../images/team/f.webp";

import ac from "../../images/team/ankit.webp";

let Akash = () => {
  return (
    <>
      <section className="about-area pb-50">
        <div className="section-header">
          <div className="section-header text-center">
            <img src={ac} className="w100" alt="" />
          </div>
        </div>
        <div className="container">
          <div className="tab-pane mt-50" id="maya">
            <div className="row">
              <div className="col-md-12">
                <div className="profile-details">
                  <h3>The Number Cruncher</h3>
                  <hr />
                  <p>
                    “When you want something, all the universe conspires in
                    helping you to achieve it.” – The Alchemist
                  </p>
                  <br />
                  <p>
                    Akash holds an MBA in Finance and hails from the wine
                    capital of India – Nashik  Always on the lookout for
                    adventure, Akash firmly believed that life had so much more
                    to offer beyond his everyday routine.
                  </p>
                  <br />
                  <p>
                    With this desire to seek a new challenge, a red pill
                    decision eventually led him to leave his corporate life and
                    join RadixCap to help entrepreneurs and businesses grow to
                    their full potential.
                  </p>
                  <br />
                  <p>
                    Akash loves writing and has regularly been contributing
                    articles for 'WallStreetOasis' - one of the largest US based
                    financial community, in his spare time.
                  </p>
                  <br />
                  <p>
                     He is also a huge football enthusiast and has been
                    supporting his beloved 'Liverpool Football Club' from as
                    long as he can remember. Mention "CORNER TAKEN QUICKLY...
                    ORIGIII!" in front of him and see him go wild!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area pt-40 pb-50 animate__animated animate__fadeInUp animate__delay-3s animate__slow	2s">
        <div className="container">
          <div className="section-header mb-100">
            <div className="section-header text-center mb-50">
              <h4 className="section-title mb-25">
                <span>
                  <img src={shape1} className="mr-10" alt="" />
                </span>
                Other Members
                <span>
                  <img src={shape2} className="ml-10" alt="" />
                </span>
              </h4>
            </div>
          </div>

          <div className="d-flex">
            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/abhishek">
                  <img src={b} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Abhishek Taparia <br />
                    <span>
                      <small>(Co-Founder & CEO)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/maya">
                  <img src={c} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Maya Taparia <br />
                    <span>
                      <small>(Co-Founder & COO)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/mahesh">
                  <img src={a} className="img-responsive" alt="profile" />
                  <h6 className="namePosition">
                    Mahesh T.D. <br />
                    <span>
                      <small>(VP)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/vignesh">
                  <img src={d} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Vignesh Ramanan <br />{" "}
                    <span>
                      <small>(Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/ashish">
                  <img src={f} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Ashish Chacko <br />{" "}
                    <span>
                      <small>(Senior Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Akash;
