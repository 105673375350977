import React from "react";

import logo from "../images/logo/logo.png";


// function openNav() {
//   document.getElementById("mySidenav").style.width = "250px";
// }

// function closeNav() {
//   document.getElementById("mySidenav").style.width = "0";
// };


let Header = () => {

  return (
    <>
    <nav className="navbar navbar-expand-lg bg-light" id="nav">
      <div className="container-fluid">
        <a href="/" className="logo"><img src={logo} alt="logo" /></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="/about_us">About Us</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/team">Team</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/service">Services</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/credential">Credentials</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/case_study">Case Studies</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    </>
  );
};

export default Header;
