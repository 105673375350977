import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import slide from "../../images/banner/slide.webp";
import team from "../../images/banner/team.webp";
import grow from "../../images/banner/grow.webp";

let Hero = () => {
  return (
    <>
      <div>
        <div className="sliderHero">
          <div className="row">
            <div className="col-lg-12">
              <OwlCarousel items={1} loop autoplay={true}>
                <div>
                  <img src={grow} alt="not-found" />
                </div>
                <div>
                  <img src={slide} alt="not-found" />
                </div>
                <div>
                  <img src={team} alt="not-found" />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Hero;
