import React from "react";

import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

let Career = () => {
  return (
    <>
      <section className="about-area pt-50 pb-100">
        <div className="container Carerre">
          <div className="section-header mb-40">
            <div className="section-header text-center mb-50">
              <h4 className="sub-heading mb-25">
                <span>
                  <img src={shape1} className="mr-10" alt="" />
                </span>
                Career Opportunity
                <span>
                  <img src={shape2} className="ml-10" alt="" />
                </span>
              </h4>
              <h2 className="section-title">Job Openings</h2>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="contact-form">
                <form>
                  <div className="form-group mt-25">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Your Name *"
                    />
                  </div>
                  <div className="form-group mt-25">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email Address *"
                    />
                  </div>
                  <div className="form-group mt-25">
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="Contact No. *"
                    />
                  </div>
                  <div className="form-group mt-25">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Why Investment Banking? *"
                    ></textarea>
                  </div>

                  <div className="form-group mt-25">
                    <label htmlFor="upload">Upload Resume *</label>
                    <input type="file" name="upload" id="upload" />
                  </div>
                  <button
                    id="btnX"
                    type="submit"
                    className="site-btn site-btn__s2 mt-35"
                  >
                    <span className="icon icon__black">
                      <i className="fa fa-arrow-right"></i>
                    </span>
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Career;
