import React from "react";
import "./accordian.css";
import snapmint from "../../images/about/snapm.png";
import a from "../../images/about/p5.png";
// import b from "../../images/about/p7.png";
import b from "../../images/about/SSnapmint.png";
import c from "../../images/about/pru1.png";
import d from "../../images/about/kae1.png";
import e from "../../images/about/rad1.png";
import f from "../../images/about/mul1.png";

let Snapmint = () => {
  return (
    <>
      <div className="container my-5">
        <div className="roundBorder">
          <div className="row">
            <div className="col-12">
              <h2 className="titleServ">
                Snapmint – One of the Leading Purchase Finance Co in India
              </h2>
              <br />
              <div className="lineServ"></div>
            </div>
            <div className="col-lg-12">
              <div className="caseImg">
                <img src={snapmint} alt="" />
              </div>
            </div>
            <div className="col-lg-12 mt-50">
              {/* <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>CURRENT STATUS</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="AccordionItemPanel">
                    <p>
                      <b>Growth</b> – Company proved its execution mettle and scaled 20x in terms of Gross Transaction Value (GTV) in a short space of time
                    </p>
                    <p className="pp1">
                      <b>Profitability </b> – With a positive unit economics, Snapmint will achieve profitability in the near future
                    </p>
                    <p><b>Platform for Market Dominance</b> – All groundwork done for next phase of growth to become consumers go to liquidity partner</p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion> */}

              <div class="accordion" id="accordionExample">
                {/* <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <span>OUR THESIS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>
                          <b>Sector Outlook</b> – Bullish on startups having end
                          use focused lending with majority of income earned
                          from merchants
                        </p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">
                          <b>Founding Team</b> – Seasoned Entrepreneurs with
                          clear vision & on-ground market intelligence
                        </p>
                      </div>
                      <div className="oneee">
                        <p>
                          <b>Product Market Fit</b> – Differentiated Business
                          Model with 4times more capital efficient then its
                          competitors
                        </p>
                      </div>
                      <div className="twooo">
                        <p className="pp1">
                          <b>Proof of Concept</b> – Over 70k consumers served
                          with &#8250; INR 1400Mn purchases financed
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <span>INVESTORS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <a href="https://prudentim.com/">
                          <img src={c} alt="" width="300px" height="150px" />
                        </a>
                        <a href="https://kae-capital.com/">
                          <img src={d} alt="" width="300px" height="150px" />
                        </a>
                        <a href="#">
                          <img src={f} alt="" width="300px" height="150px" />
                        </a>
                        <a href="https://radixcap.in/">
                          <img src={e} alt="" width="250px" height="150px" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span>CURRENT STATUS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="oneee">
                        <p>
                          <b>Growth</b> – Company proved its execution mettle
                          and scaled 20x in terms of Gross Transaction Value
                          (GTV) in a short space of time
                        </p>
                        <p>
                          <b>Profitability </b> – With a positive unit
                          economics, Snapmint will achieve profitability in the
                          near future
                        </p>
                        <p>
                          <b>Platform for Market Dominance</b> – All groundwork
                          done for next phase of growth to become consumers go
                          to liquidity partner
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div class="flip-box-container">
            <div class="flip-box">
              <div class="flip-box-inner">
                <div class="flip-box-front">
                  <h3>End of 2019 - FinTech Market wave</h3>
                  {/* <p>Front content goes here</p> */}
                </div>
                <div class="flip-box-back">
                  <div class="logos">
                    <img class="logo" src={a} />
                  </div>
                  <ol>
                    <li>
                      Tech disruption in <b>Personal Lending</b> for Bharat.
                    </li>
                    <li>
                      <b>Checkout finance -</b> US model will be replicated in
                      India.
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div class="flip-box">
              <div class="flip-box-inner">
                <div class="flip-box-front">
                  <h3>Radix Deep Dive</h3>
                  {/* <p>Front content goes here</p> */}
                </div>
                <div class="flip-box-back">
                  <ol>
                    <li>
                      Personal Lending space is a <b>Cost of Capital game</b>.
                    </li>
                    <li>
                      Fintech should play{" "}
                      <b>where OPEX disruption is the moat</b>.
                    </li>
                    <li>
                      Financing <b>specific purchases of healthy customers </b>
                      is the key through digital play.
                    </li>
                    <li>
                      <b>Powering merchant will be the key </b>and it has to be
                      beyond Amazon & Flipkart.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-80 mb-30">
            <h2 className="titleServ">
              Snapmint – Emerging as India’s most successful BNPL player
            </h2>
            <br />
            <div className="lineServ"></div>
            {/* <p>
              BNPL start-up financing Gen-Z purchases in specific categories in
              tier 2-4 cities
            </p> */}
          </div>
          <div className="content">
            <div className="row">
              <div className="col-md-6">
                <img src={b} alt="Snapmint Image" className="img-fluid" />
              </div>
              <div className="col-md-6">
                <div className="additional-info3 ">
                  <h1 class="abc">
                    <strong>Snapmint Today</strong>
                    <div className="lineServ"></div>
                  </h1>
                  <h1>
                    <strong>16x</strong>
                  </h1>
                  <h5>Growth in GTV </h5>
                  <br />
                  <h1>
                    <strong>12x</strong>
                  </h1>
                  <h5>Growth in customers </h5>
                  <br />
                  <h1>
                    <strong>A Minicorn!</strong>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center my-5">
          <a href="/neewee">
            <button className="btnArrow">
              <i className="fa-solid fa-arrow-left"></i> Neewee
            </button>
          </a>
          &nbsp;&nbsp;
          <a href="/sukhhi">
            <button className="btnArrow">
              Sukhhi <i className="fa-solid fa-arrow-right"></i>
            </button>
          </a>
        </div>
      </div>
    </>
  );
};
export default Snapmint;
