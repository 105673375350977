import React from "react";

let Contactus = () => {
  return (
    <>
      <section className="gta-area pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div id="contact-map">
                <img src="https://images.unsplash.com/photo-1633765607727-4a4b60396a9b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80" alt="imgg" />
              </div>
            </div>
            <div className="col-xl-6 pl-70">
              <div className="section-header mb-50">
                <h4 className="sub-heading mb-15">Send Message</h4>
                <h2 className="section-title">Get in Touch</h2>
              </div>
              <div className="contact-form">
                <form id="contact-form">
                  <div className="form-group mt-25">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="form-group mt-25">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="form-group mt-25">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Your Message"
                    ></textarea>
                  </div>
                  <button type="submit" className="site-btn site-btn__s2 mt-15">
                    <span className="icon icon__black">
                      <i className="fa fa-arrow-right"></i>
                    </span>
                    Contact us
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contactus;
