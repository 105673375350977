import React from "react";

import ab from "../../images/team/abhishek.webp";
import shape1 from "../../images/shape/heading-shape-1.png";
import shape2 from "../../images/shape/heading-shape-2.png";

import a from "../../images/team/a.webp";
import c from "../../images/team/c.webp";
import d from "../../images/team/d.webp";
import e from "../../images/team/e.webp";
import f from "../../images/team/f.webp";

let Abhishek = () => {
  return (
    <>
      <section className="about-area pb-50">
        <div className="section-header">
          <div className="section-header text-center">
            <img src={ab} className="w100" alt="" />
          </div>
        </div>
        <div className="container">
          <div className="tab-pane mt-50" id="abhishek">
            <div className="row">
              <div className="col-md-12">
                <div className="profile-details">
                  <h3>Captain of Ship</h3>
                  <hr />
                  <p>
                    14 years in Funding ecosystem – 8 as an iBanker, 6 as
                    Founder and more recently as Angel investor.
                  </p>
                  <br />
                  <p>
                    A Passionate ibanker who loves engaging with & learning from
                    the most driven & optimistic tribe of our planet – The
                    Founding Teams. Fortunate to have interacted with hundreds
                    of founders and closely worked with several Rockstars.
                  </p>
                  <br />
                  <p>
                    In his more than a decade long I-banking experience, he has
                    worked across geographies in multiple sectors including
                    Consumer, E-Commerce, Technology, Analytics, Renewable
                    Energy, Media-tech, Infrastructure, etc. Prior to
                    Co-founding Radix, he has worked with Investment Banking
                    teams of Royal Bank of Scotland, Feedback Infra & Singhi
                    Advisors.
                  </p>
                  <br />
                  <p>
                    Abhishek is passionate about skill building and has taught
                    more than 2000 hours. If not teaching, he can be found in
                    squash courts during weekends. One can hold him for hours as
                    long as his mug is refilled with amazing coffee.
                  </p>
                  <br />
                  <p>
                    Abhishek is a MBA (Major – Investment Banking) from S P Jain
                    School of Global Management- Dubai, Singapore and has
                    completed B. E. in biotechnology from BMS College of
                    Engineering, Bengaluru.
                  </p>
                  <br />
                  <p>
                    He has served as an Independent Director on the boards of
                    several companies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area pt-40 pb-50 animate__animated animate__fadeInUp animate__delay-3s animate__slow	2s">
        <div className="container">
          <div className="section-header mb-100">
            <div className="section-header text-center mb-50">
              <h4 className="section-title mb-25">
                <span>
                  <img src={shape1} className="mr-10" alt="" />
                </span>
                Other Members
                <span>
                  <img src={shape2} className="ml-10" alt="" />
                </span>
              </h4>
            </div>
          </div>

          <div className="d-flex">

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/maya">
                  <img src={c} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Maya Taparia <br />
                    <span>
                      <small>(Co-Founder & COO)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/mahesh">
                  <img src={a} className="img-responsive" alt="profile" />
                  <h6 className="namePosition">
                    Mahesh T.D. <br />
                    <span>
                      <small>(VP)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/akash">
                  <img src={e} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                  Akash Bagul <br />{" "}
                    <span>
                      <small>(Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/vignesh">
                  <img src={d} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                  Vignesh Ramanan <br />{" "}
                    <span>
                      <small>(Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

            <div className="lineV1"></div>
            <div className="cardTee1">
              <li className="nav-item">
                <a href="/ashish">
                  <img src={f} alt=" profile " className="img-responsive" />
                  <h6 className="namePosition">
                    Ashish Chacko <br />{" "}
                    <span>
                      <small>(Senior Analyst)</small>
                    </span>
                  </h6>
                </a>
              </li>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Abhishek;
