import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import serv3 from "../../images/service/serv3.webp";
import serv4 from "../../images/service/serv4.webp";
import serv1 from "../../images/service/serv1.webp";
import serv2 from "../../images/service/serv2.webp";

let Serv = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const onOpenModal1 = () => setOpen1(true);
  const onCloseModal1 = () => setOpen1(false);

  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal2 = () => setOpen2(false);

  const onOpenModal3 = () => setOpen3(true);
  const onCloseModal3 = () => setOpen3(false);

  return (
    <>
      <div className="container animate_animated animatefadeInUp animatedelay-1s animate_slow	2s">
        <div className="roundBorder">
          <div className="row" id="serviceFlex">
            <div className="col-lg-6" id="serviceWidth">
                <div class="container1">
                  <img src={serv1} alt="Snow" style={{ width: "100%" }} />
                  <button class="btn1" onClick={onOpenModal}>
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </button>
                </div>
                {open && (
                  <Modal open={open} onClose={onCloseModal} classNames={{
                    overlayAnimationIn: 'customEnterOverlayAnimation',
                    overlayAnimationOut: 'customLeaveOverlayAnimation',
                    modalAnimationIn: 'customEnterModalAnimation',
                    modalAnimationOut: 'customLeaveModalAnimation',
                  }}
                  animationDuration={1000} center>
                    <div className="roundBorder">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="contentServ1">
                            <h4>Equity Solution</h4>
                            <hr />
                            <ul>
                              <li>
                                <i className="fa fa-angle-right"></i>&nbsp;
                                Focussed on Early / Growth stage companies, our
                                Equity solutions deal with originating,
                                structuring and executing equity and equity-linked
                                transactions for our clients across sectors.
                              </li>
                              <br />
                              <li>
                                <i className="fa fa-angle-right"></i> &nbsp;We
                                work with a wide variety of domestic and global
                                investors including :
                              </li>
                              <br />
                              <li>
                                <p>
                                  <b>Venture Capital</b>
                                </p>
                                <p>
                                  <b>Private Equity</b>
                                </p>
                                <p>
                                  <b>Family Office</b>
                                </p>
                                <p>
                                  <b>Strategic Investors</b>
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                )}
            </div>

            <div className="col-lg-6" id="serviceWidth">
                <div class="container1">
                  <img src={serv2} alt="Snow" style={{ width: "100%" }} />
                  <button class="btn1" onClick={onOpenModal1}>
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </button>
                </div>
                {open1 && (
                  <Modal open={open1} onClose={onCloseModal1} classNames={{
                    overlayAnimationIn: 'customEnterOverlayAnimation',
                    overlayAnimationOut: 'customLeaveOverlayAnimation',
                    modalAnimationIn: 'customEnterModalAnimation',
                    modalAnimationOut: 'customLeaveModalAnimation',
                  }}
                  animationDuration={1000} center>
                      <div className="roundBorder">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="contentServ1">
                              <h4>Debt Solution</h4>
                              <hr />
                              <ul>
                                <li>
                                  <i className="fa fa-angle-right"></i>&nbsp;
                                  Based on business requirement, we offer
                                  solutions for our client's debt needs as per
                                  their strategic objectives and financial
                                  flexibility.
                                </li>
                                <br />
                                <li>
                                  <i className="fa fa-angle-right"></i> &nbsp; Our
                                  various offerings include:
                                </li>
                                <li>
                                  <p>
                                    <b>- Venture Debt : </b>A minimally dilutive
                                    form of capital, for early stage companies and
                                    Start-ups
                                  </p>
                                  <p>
                                    <b>- Structured Finance : </b>Alternate,
                                    Innovative, and non-dilutive source of
                                    financing for unique needs of a startup,
                                    allowing for efficient use of existing capital
                                    & cash-flows. Funding solutions based on exact
                                    need, expected revenue and overall
                                    serviceability.
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                )}
            </div>

            <div className="col-lg-6 mt-25" id="serviceWidth">
              <div class="container1">
                <img src={serv3} alt="Snow" style={{ width: "100%" }} />
                <button class="btn1" onClick={onOpenModal2}>
                  <i class="fa-solid fa-ellipsis-vertical"></i>
                </button>
              </div>
              {open2 && (
                <Modal open={open2} onClose={onCloseModal2} classNames={{
                  overlayAnimationIn: 'customEnterOverlayAnimation',
                  overlayAnimationOut: 'customLeaveOverlayAnimation',
                  modalAnimationIn: 'customEnterModalAnimation',
                  modalAnimationOut: 'customLeaveModalAnimation',
                }}
                animationDuration={1000} center>
                  <div className="roundBorder">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="contentServ1">
                          <h4>Virtual CFO</h4>
                          <hr />
                          <ul>
                            <li>
                              <i className="fa fa-angle-right"></i>&nbsp;
                              <b>Analysis :</b> We help preparing board meeting
                              deck, business plan and monthly MIS. We provide
                              support for periodic analysis of Cash flow
                              management, advise on recommended actions to
                              achieve business plan targets and planned growth.
                            </li>
                            <br />
                            <li>
                              <i className="fa fa-angle-right"></i>&nbsp;
                              <b>Control :</b> We support in all Finance Control
                              functions for the organization including
                              Controlling of Finance, General Accounting,
                              General Ledger, Treasury management.
                            </li>
                            <br />
                            <li>
                              <i className="fa fa-angle-right"></i>&nbsp;
                              <b>Review :</b> We review all financial plans and
                              budgets and monitor all periodic financial
                              reports. We work with founders to regularly look
                              for cost reduction opportunities and optimize
                              Working Capital and manage cash flow by tracking
                              transactions and regularly reviewing internal
                              reports.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              )}
            </div>

            <div className="col-lg-6 mt-25" id="serviceWidth">
                <div class="container1">
                  <img src={serv4} alt="Snow" style={{ width: "100%" }} />
                  <button class="btn1" onClick={onOpenModal3}>
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </button>
                </div>
                {open3 && (
                  <Modal open={open3} onClose={onCloseModal3} classNames={{
                    overlayAnimationIn: 'customEnterOverlayAnimation',
                    overlayAnimationOut: 'customLeaveOverlayAnimation',
                    modalAnimationIn: 'customEnterModalAnimation',
                    modalAnimationOut: 'customLeaveModalAnimation',
                  }}
                  animationDuration={1000} center>
                    <div className="roundBorder">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="contentServ1">
                            <h4>Merger & Acquisitions</h4>
                            <hr />
                            <b>
                              <i>Buy / Sell Side</i>
                            </b>
                            <br />
                            <br />
                            <ul>
                              <li>
                                <i className="fa fa-angle-right"></i>&nbsp;
                                <b>Target Identification :</b> Our knowledge
                                repositories, we scan and identify opportunities
                                across sectors and geographies, keeping in mind
                                the strategic objectives and synergies.
                              </li>
                              <br />
                              {/* <li>
                                <i className="fa fa-angle-right"></i>{" "}
                                <b>Interaction with Target :</b>{" "}
                              </li>
                              <br /> */}
                              <li>
                                <i className="fa fa-angle-right"></i>&nbsp;
                                <b>Target Due Diligence :</b> We prepare
                                Information Request list and analyse incoming data
                                to identify key issues and validate assumptions
                                and projections.
                              </li>
                              <br />
                              <li>
                                <i className="fa fa-angle-right"></i>&nbsp;
                                <b>Deal Closure :</b> Complete support in Project
                                managing the entire closing process from support
                                in drafting documents to fulfilling closing
                                conditions.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Serv;
